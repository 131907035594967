import React from 'react';
import { BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/josu%C3%A9-cereme/" target="_blank">
        <BsLinkedin/>    
      </a>
    </div>
  </div>
);

export default SocialMedia;